import { MACHINE_TYPE } from 'rapidfab/constants';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Container, Nav, NavItem, NavLink } from 'react-bootstrap';

import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';

import DowntimeContainer from 'rapidfab/containers/downtime/DowntimeContainer';
import PostProcessorFormContainer from 'rapidfab/containers/records/PostProcessorFormContainer';
import ChipNote from './ChipNote';

const PostProcessor = ({
  handleSelectTab,
  postProcessor,
  route,
  tab,
  locationState,
  subLocationState,
}) => {
  const { name, uri, uuid } = postProcessor || {};
  // variable for displaying ChipNote bubble for Non-conformance processors
  const [showChipNote, setShowChipNote] = useState(false);

  return (
    <Container fluid>
      <BreadcrumbNav
        breadcrumbs={['assets', 'postProcessors', uuid ? `${name} (${getShortUUID(uuid)})` : 'New']}
      />

      <div className="wrap-text page-header">
        <h1 className="mb15">{name || 'New Post Processor'}</h1>
        {(showChipNote || postProcessor?.post_processor_special_type === 'non_conformance_review') && <ChipNote />}
      </div>

      {uuid && (
        <Nav variant="tabs" activeKey={tab} onSelect={handleSelectTab}>
          <NavItem>
            <NavLink eventKey="1">Summary</NavLink>
          </NavItem>
          <NavItem>
            <NavLink disabled={!uri} eventKey="2">
              Downtime
            </NavLink>
          </NavItem>
        </Nav>
      )}
      {tab === '1' && (
        <PostProcessorFormContainer
          displayChipNote={setShowChipNote}
          processor={postProcessor}
          route={route}
          isService={false}
          locationState={locationState}
          subLocationState={subLocationState}
        />
      )}
      {tab === '2' && (
        <DowntimeContainer machineType={MACHINE_TYPE.POST_PROCESSOR} machineUri={uri} />
      )}
    </Container>
  );
};

PostProcessor.defaultProps = {
  postProcessor: null,
};

PostProcessor.propTypes = {
  handleSelectTab: PropTypes.func.isRequired,
  postProcessor: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uri: PropTypes.string,
    post_processor_special_type: PropTypes.string,
  }),
  route: PropTypes.shape({ uuid: PropTypes.string }).isRequired,
  tab: PropTypes.number.isRequired,
  locationState: PropTypes.shape({}).isRequired,
  subLocationState: PropTypes.shape({}).isRequired,
};

export default PostProcessor;
