/* eslint-disable react/prop-types, class-methods-use-this */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar, Dropdown, DropdownButton, Button } from 'react-bootstrap';
import _join from 'lodash/join';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _isEqual from 'lodash/isEqual';
import _differenceBy from 'lodash/differenceBy';
import { Droppable } from 'react-drag-and-drop';
import { FEATURES } from 'rapidfab/constants';
import Loading from 'rapidfab/components/Loading';
import Feature from 'rapidfab/components/Feature';
import SelectPiecesPanelHeader from 'rapidfab/components/records/run/SelectPiecesPanelHeader';
import RunLineItem from 'rapidfab/components/records/run/RunLineItem';
import _groupBy from 'lodash/groupBy';
import { extractUuid } from 'rapidfab/utils/uuidUtils';

class LineItemList extends Component {
  constructor(props) {
    super(props);

    this.handleSearchValue = this.handleSearchValue.bind(this);
    this.onShiftToggle = this.onShiftToggle.bind(this);
    this.onShiftUp = this.onShiftUp.bind(this);
    this.onShiftDown = this.onShiftDown.bind(this);
    this.onPageLimitSelect = this.onPageLimitSelect.bind(this);
    this.setFirstItem = this.setFirstItem.bind(this);
    this.getSortDirection = this.getSortDirection.bind(this);

    this.handleOrdering = this.handleOrdering.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.handlePrevPage = this.handlePrevPage.bind(this);

    this.state = {
      shiftPressed: false,
      shiftStart: props.shiftStart,
      shiftEnd: null,
      searchValue: '',
      pageLimitValues: [10, 20, 50],
      pageLimit: 10,
      pageSorting: 'created',
      pageSortingDirection: 'desc',
      activePage: 1,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onShiftDown, false);
    document.addEventListener('keyup', this.onShiftUp, false);

    const { selectedPrinter } = this.props;

    if (selectedPrinter) {
      this.refreshLineItems();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedPrinter,
      selectedLocations,
      selectedMaterials,
      selectedWorkflows,
      searchQuery,
      layerThicknessSearch,
    } = this.props;
    const { activePage, pageLimit, pageSorting, pageSortingDirection } = this.state;

    // Printer has been changed, update all line items related to printer
    if (
      selectedPrinter !== null &&
      (selectedPrinter !== prevProps.selectedPrinter)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ activePage: 1 }, () => this.refreshLineItems());
    }

    if (
      // For single-select checking by value is not enough
      !_isEqual(selectedLocations, prevProps.selectedLocations) ||
      !_isEqual(selectedMaterials, prevProps.selectedMaterials) ||
      !_isEqual(selectedWorkflows, prevProps.selectedWorkflows) ||
      !_isEqual(layerThicknessSearch, prevProps.layerThicknessSearch)
    ) {
      this.refreshLineItems();
    }

    if (
      searchQuery !== prevProps.searchQuery
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        searchValue: searchQuery,
        activePage: 1,
      }, () => this.refreshLineItems());
    }

    if (activePage !== prevState.activePage) {
      this.refreshLineItems();
    }

    if (pageLimit !== prevState.pageLimit) {
      this.refreshLineItems();
    }

    if (pageSorting !== prevState.pageSorting) {
      this.refreshLineItems();
    }

    if (pageSortingDirection !== prevState.pageSortingDirection) {
      this.refreshLineItems();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onShiftDown, false);
    document.removeEventListener('keyup', this.onShiftUp, false);
  }

  handleSearchValue(event) {
    const { value } = event.target;

    this.setState({
      searchValue: value,
      activePage: 1,
    });
  }

  handleOrdering(key) {
    let { pageSorting, pageSortingDirection } = this.state;
    if (pageSorting === key) {
      pageSortingDirection = pageSortingDirection === 'asc' ? 'desc' : 'asc';
    } else {
      pageSorting = key;
      pageSortingDirection = 'asc';
    }

    this.setState({ pageSorting, pageSortingDirection });
  }

  handleNextPage() {
    this.setState({
      activePage: this.state.activePage + 1,
    });
  }

  handlePrevPage() {
    this.setState({
      activePage: Math.max(this.state.activePage - 1, 1),
    });
  }

  onShiftToggle(piece) {
    const { pieces, onSelectGroup } = this.props;
    let piecesArray = [];

    if (this.state.shiftStart !== null) {
      const indexEnd = pieces.findIndex(x => x.uuid === piece.uuid);

      const shiftStart = Math.min(this.state.shiftStart, indexEnd);
      const shiftEnd = Math.max(this.state.shiftEnd, indexEnd);
      piecesArray = pieces.slice(shiftStart, shiftEnd + 1);

      this.setState({ shiftStart: null }, () => {
        onSelectGroup(piecesArray);
      });
    }
  }

  onShiftDown(event) {
    if (event.keyCode === 16 && !this.state.shiftPressed) {
      this.setState({ shiftPressed: true, shiftEnd: null });
    }
  }

  onShiftUp(event) {
    if (event.keyCode === 16 && this.state.shiftPressed) {
      this.setState({
        shiftPressed: false,
      });
    }
  }

  onPageLimitSelect(eventKey) {
    this.setState({ pageLimit: eventKey, activePage: 1 });
  }

  getSortDirection(name) {
    const { pageSorting, pageSortingDirection } = this.state;
    if (!pageSorting || (name !== pageSorting)) {
      return null;
    }

    if (pageSortingDirection === 'asc') {
      // "Up" symbol
      return ' ↑';
    }
    // "Down" symbol
    return ' ↓';
  }

  setFirstItem(focusedPieces) {
    const {
      selected,
      onSelectGroup,
      onSelect,
    } = this.props;

    if (_differenceBy(focusedPieces, selected, 'uri').length > 0) {
      onSelectGroup(focusedPieces);
    } else {
      onSelect(focusedPieces);
    }
  }

  refreshLineItems() {
    const {
      selectedPrinter,
      selectedLocations,
      selectedMaterials,
      selectedWorkflows,
      layerThicknessSearch,
    } = this.props;
    const { activePage, pageLimit, searchValue, pageSorting, pageSortingDirection } = this.state;

    const filters = {
      // Prevent loading of line items without models, since those are not shown in the grid
      'additive.no_model_upload': false,
    };

    if (selectedLocations.length > 0) {
      filters.location = _map(selectedLocations, 'uri');
    }

    if (selectedPrinter) {
      filters.available_for_printer = selectedPrinter.uri;
    }

    if (layerThicknessSearch) {
      filters['additive.layer_thickness'] = layerThicknessSearch;
    }

    const filtersByFields = {
      workflow: selectedWorkflows,
      material: selectedMaterials,
    };

    _forEach(filtersByFields, (data, key) => {
      if (data && data.length > 0) {
        const filterURLs = data.map(element => element.uri);
        const filterString = _join(filterURLs, ',');

        if (filterString) {
          filters[key] = filterString;
        }
      }
    });

    const offsetPage = (activePage - 1) * pageLimit;
    const pageParams = {
      limit: pageLimit,
      offset: offsetPage,
    };

    const searchParams = {};

    if (searchValue !== '') {
      searchParams.order_name = searchValue;
    }

    const queryParams = {};
    if (pageSorting) {
      queryParams.sort = `${pageSortingDirection === 'desc' ? '-' : ''}${pageSorting}`;
    }

    this.props.onLoadLineItems(filters, pageParams, searchParams, queryParams);
  }

  render() {
    const {
      selected,
      onDeactivate,
      fetching,
      lineItemListStore,
      toggleChecked,
      lineItems,
      onToggleChange,
      selectedPrinter,
      inactivePieces,
      labelRelationships,
      isGeneralMFGLanguageEnabled,
    } = this.props;
    const { pageLimitValues, pageLimit } = this.state;
    const isAvailableNextLink = (lineItemListStore.links && lineItemListStore.links.next !== null);
    const isAvailablePreviousLink = (lineItemListStore.links && lineItemListStore.links.prev !== null);

    const isPrinterSelected = (selectedPrinter !== null);

    // Since piece.line_item and line_item.uri are different uris
    // (line-item endpoint is different for build plate page),
    // but the same uuid, we need to use only uuids here
    const inactivePiecesByLineItemUuid = _groupBy(
      inactivePieces,
      ({ line_item: lineItemUri }) => extractUuid(lineItemUri),
    );

    return (
      <>
        <SelectPiecesPanelHeader
          toggleChecked={toggleChecked}
          onToggleChange={onToggleChange}
          fetching={this.props.initialFetching}
          selectLabel="Select Line Items"
        />

        <div className="vm-panel-body panel-body-bordered">
          <div className="table-responsive">
            <Droppable types={['buildplatepiece', 'buildplatelineitem']} onDrop={onDeactivate}>
              <table
                className="table table-fixed table-hover m-b-0 no-outer-border"
                id="lineItemsTable"
              >
                <thead>
                  <tr>
                    <td>Render</td>
                    {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
                    <td
                      className="sorting-td"
                      onClick={() => {
                        this.handleOrdering('order_name');
                      }}
                    >
                      Order Name
                      { this.getSortDirection('order_name') }
                    </td>
                    <td
                      className="sorting-td"
                      onClick={() => {
                        this.handleOrdering('size');
                      }}
                    >
                      Size
                      { this.getSortDirection('size') }
                    </td>
                    <td
                      className="sorting-td"
                      onClick={() => {
                        this.handleOrdering('material_name');
                      }}
                    >
                      Material
                      { this.getSortDirection('material_name') }
                    </td>
                    <td>Layer Thickness</td>
                    <td
                      className="sorting-td"
                      onClick={() => {
                        this.handleOrdering('order_due_date');
                      }}
                    >
                      Due Date
                      { this.getSortDirection('order_due_date') }
                    </td>
                    <Feature featureName={FEATURES.PRIORITY}>
                      <td
                        className="sorting-td"
                        onClick={() => {
                          this.handleOrdering('priority');
                        }}
                      >
                        Priority
                        { this.getSortDirection('priority') }
                      </td>
                    </Feature>
                    {/* eslint-enable jsx-a11y/no-noninteractive-element-interactions */}
                  </tr>
                </thead>
                <tbody style={{ height: 'auto', maxHeight: 'inherit' }} className="no-scrollbar">
                  {
                    fetching && <tr><td><Loading /></td></tr>
                  }
                  {(!fetching && !lineItems.length) && (
                    <tr>
                      <td>
                        {
                          (!isPrinterSelected) ?
                            `Select ${isGeneralMFGLanguageEnabled ? 'Production Device' : 'Printer'} to list available Line Items`
                            :
                            'No results found'
                        }
                      </td>
                    </tr>
                  )}

                  {
                    !fetching && lineItems.map(lineItem => {
                      // Filter pieces to only inactive ones
                      const inactiveLineItemPieces =
                        inactivePiecesByLineItemUuid[lineItem.uuid] || [];
                      const inactiveLineItemPieceUris = _map(inactiveLineItemPieces, 'uri');

                      // Get label relationships for all pieces in this one line item
                      const labelRelationshipsForLineItemPieces = labelRelationships.filter(labelRelationship =>
                        inactiveLineItemPieceUris.includes(labelRelationship.target_uri),
                      );

                      return (
                        inactiveLineItemPieces.length > 0 && (
                          <RunLineItem
                            key={lineItem.uuid}
                            pieces={inactiveLineItemPieces}
                            selected={
                              _differenceBy(inactiveLineItemPieces, selected, 'uri').length === 0
                            }
                            lineItem={lineItem}
                            onShiftToggle={this.onShiftToggle}
                            setFirstItem={this.setFirstItem}
                            labelRelationshipsForLineItemPieces={labelRelationshipsForLineItemPieces}
                          />
                        )
                      );
                    })
                  }
                </tbody>
              </table>
            </Droppable>
          </div>
        </div>
        {isPrinterSelected && (
          <div className="pagination-panel">
            <ButtonToolbar className="pull-left">
              <DropdownButton title={pageLimit} id="pageLimitSelector-LineItemsTab">
                {pageLimitValues.map(value => (
                  <Dropdown.Item
                    active={value === pageLimit}
                    key={value}
                    eventKey={value}
                    onClick={() => this.onPageLimitSelect(value)}
                  >
                    {value}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </ButtonToolbar>

            <ButtonToolbar className="pull-right">
              <Button
                disabled={!isAvailablePreviousLink}
                onClick={this.handlePrevPage}
                size="sm"
              >Prev
              </Button>
              <Button
                disabled={!isAvailableNextLink}
                size="sm"
                onClick={this.handleNextPage}
              >Next
              </Button>
            </ButtonToolbar>
          </div>
        )}
      </>
    );
  }
}

LineItemList.defaultProps = {
  onToggleChange: () => true,
  fetching: false,
  pieces: [],
  selected: [],
  selectedLocations: [],
  selectedMaterials: [],
  selectedWorkflows: [],
  layerThicknessSearch: '',
  selectedPrinter: null,
  searchQuery: null,
  toggleChecked: false,
  shiftStart: null,
};

LineItemList.propTypes = {
  onDeactivate: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onToggleChange: PropTypes.func,
  onSelectGroup: PropTypes.func.isRequired,
  onLoadLineItems: PropTypes.func.isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pieces: PropTypes.arrayOf(PropTypes.shape({})),
  selected: PropTypes.arrayOf(PropTypes.shape({})),
  lineItemListStore: PropTypes.shape({
    links: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string,
    }),
  }).isRequired,
  fetching: PropTypes.bool,
  toggleChecked: PropTypes.bool,
  searchQuery: PropTypes.string,
  selectedLocations: PropTypes.arrayOf(PropTypes.shape({})),
  selectedMaterials: PropTypes.arrayOf(PropTypes.shape({})),
  selectedWorkflows: PropTypes.arrayOf(PropTypes.shape({})),
  layerThicknessSearch: PropTypes.string,
  inactivePieces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedPrinter: PropTypes.shape({
    uri: PropTypes.string,
  }),
  shiftStart: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
  initialFetching: PropTypes.bool.isRequired,
  labelRelationships: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default LineItemList;
