import WorkstationTabs from 'rapidfab/components/assets/WorkstationTabs';
import Locations from 'rapidfab/components/locations';
import SubLocations from 'rapidfab/components/SubLocations';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Col, Container, Dropdown, DropdownButton, FormControl, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { ROUTES, SCYLLA_MODELERS_URI, VIEW_MODE_OPTIONS } from 'rapidfab/constants';
import DisabledFor from 'rapidfab/components/DisabledFor';
import DisabledIconWithTooltip from 'rapidfab/components/DisabledIconWithTooltip';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';
import PrintersTable from 'rapidfab/components/assets/PrintersTable';
import PrintersCards from 'rapidfab/components/assets/PrintersCards';
import Loading from 'rapidfab/components/Loading';
import _range from 'lodash/range';
import { faList, faPlus, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { getIsDebugModeEnabled } from 'rapidfab/selectors';

const Printers = memo(({
  printers,
  locations,
  subLocationsByUri,
  printerTypes,
  modelers,
  fetching,
  isGroupQualificationsFeatureEnabled,
  isGeneralMFGLanguageEnabled,
  viewMode,
  setViewMode,
  isCardsView,
  pagination,
  filters,
  filterValues,
  ...cardProps
}) => {
  const isDegbugModeEnabled = useSelector(getIsDebugModeEnabled);
  const columns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'printer',
    },
    {
      type: 'modeler',
      uid: 'field.modeler',
      accessor: 'modeler',
      defaultMessage: 'Modeler',
      modelers,
      isSortable: true,
    },
    {
      type: 'text',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
    },
    {
      type: 'location',
      uid: 'field.location',
      accessor: 'location',
      defaultMessage: 'Location',
      resource: 'location',
      resources: locations,
      isSortable: true,
    },
    {
      type: 'sub_location',
      uid: 'field.sub_location',
      accessor: 'sub_location',
      defaultMessage: 'Sub-Location',
      resource: 'sub_location',
      resources: subLocationsByUri,
      isSortable: true,
    },
    {
      type: 'resource',
      uid: isGeneralMFGLanguageEnabled ? 'mfg.printerType.productionDeviceType' : 'field.printerType',
      accessor: 'printer_type',
      defaultMessage: isGeneralMFGLanguageEnabled ? 'Production Device Type' : 'Printer Type',
      slug: 'printer-type',
      resource: 'printer_type',
      resources: printerTypes,
      isSortable: true,
    },
  ];

  const {
    activePage,
    setPage,
    pageLimit,
    totalItems,
    nextPage,
    prevPage,
    totalPaginatedPages,
  } = pagination;

  const {
    globalFilter,
    setGlobalFilter,
  } = filters;

  const {
    filterValue,
    setFilterValue,
  } = filterValues;

  const renderHeaderView = viewMode => {
    const isListView = !isCardsView;
    return (
      <Row>
        <Col xs={isListView && { span: 12 }} lg={isListView && { span: 10, offset: 1 }} className="d-flex justify-content-between">
          <Col xs={4}>
            <div className="d-flex align-items-center">
              <FormControl
                type="text"
                value={filterValue}
                placeholder="Filter"
                onChange={({ target }) => {
                  setFilterValue(target.value);
                }}
              />
              <Button
                className="pull-right spacer-left"
                variant="primary"
                type="submit"
                onClick={() => setGlobalFilter(filterValue)}
                disabled={!globalFilter && !filterValue}
              >
                <FormattedMessage id="button.submit" defaultMessage="Submit" />
              </Button>
            </div>
          </Col>
          <div className="pagination-panel">
            <ButtonToolbar className="pull-right toolbar spacer-left">
              <Button
                variant="default"
                text="dark"
                className={viewMode === VIEW_MODE_OPTIONS.CARDS ? 'btn-active' : ''}
                onClick={() => setViewMode(VIEW_MODE_OPTIONS.CARDS)}
              >
                <FontAwesomeIcon icon={faThLarge} size="lg" />
              </Button>
              <Button
                text="dark"
                variant="default"
                className={viewMode === VIEW_MODE_OPTIONS.LIST ? 'btn-active' : ''}
                active={viewMode === VIEW_MODE_OPTIONS.LIST}
                onClick={() => setViewMode(VIEW_MODE_OPTIONS.LIST)}
              >
                <FontAwesomeIcon icon={faList} size="lg" />
              </Button>
            </ButtonToolbar>
            <ButtonToolbar className="pull-right">
              <Button
                disabled={activePage === 0 || fetching}
                className="spacer-right"
                onClick={prevPage}
              >Prev
              </Button>
              <DropdownButton className="spacer-right" title={`Page ${activePage + 1}`}>
                {_range(0, totalPaginatedPages).map(value => (
                  <Dropdown.Item
                    active={value === pageLimit}
                    key={value}
                    eventKey={value}
                    onClick={() => setPage(value)}
                  >
                    {value + 1}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <Button
                disabled={((
                  activePage === (totalPaginatedPages - 1) ||
                  // No need to show next button if there are no more printers to show
                  totalItems === 0
                )) || fetching}
                onClick={nextPage}
              >Next
              </Button>
            </ButtonToolbar>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Container fluid>
        <BreadcrumbNav breadcrumbs={['assets', 'printers']} />

        <Row>
          <Col xs={12} className="mb8">
            <WorkstationTabs activeKey="printers" />
          </Col>
          <Col xs={3}>
            <Locations />
          </Col>
          <Col xs={5}>
            <SubLocations />
          </Col>

          <Col xs={4}>
            <DisabledFor
              nonManager
              /* Disable for Non Managers only when `group-qualifications`
               feature is enabled. No limitations otherwise */
              alwaysEnabled={!isGroupQualificationsFeatureEnabled}
              disabledPrefix={
                isDegbugModeEnabled ? (
                  <DisabledIconWithTooltip
                    id="limitedFunctionalityDebugMode"
                    defaultMessage="This functionality usage is limited. Contact your Bureau Manager if you have any questions. Debug Info: Locked due to configuration of User Groups and Group Qualifications. See docs for more details."
                    placement="bottom"
                  />
                )
                  : <DisabledIconWithTooltip placement="bottom" />
              }
            >
              {({ disabled }) => (
                <Button
                  variant="primary"
                  size="sm"
                  className="pull-right"
                  href={getRouteURI(ROUTES.PRINTER_NEW)}
                  disabled={disabled}
                >
                  <FontAwesomeIcon icon={faPlus} />{' '}
                  <UseNonMfgLanguageFeature
                    mfgLanguageComponent={<FormattedMessage id="record.printer.add" defaultMessage="Add Printer" />}
                    nonMfgLanguageComponent={<FormattedMessage id="mfg.addPrinterType.addProductionDevice" defaultMessage="Add Production Device" />}
                  />
                </Button>
              )}
            </DisabledFor>
            <Button
              variant="primary"
              size="sm"
              title="Go To Modelers List"
              className="spacer-right pull-right"
              href={SCYLLA_MODELERS_URI}
            >
              <FormattedMessage
                id="record.modelersList"
                defaultMessage="Modelers List"
              />
            </Button>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col xs={12}>

            {fetching ? (
              <Loading />
            ) : (
              <>
                {isCardsView ? (
                  <Container>
                    <PrintersCards
                      fetching={fetching}
                      printers={printers}
                      modelers={modelers}
                      printerTypes={printerTypes}
                      locations={locations}
                      pagination={pagination}
                      renderHeaderView={renderHeaderView}
                      subLocationsByUri={subLocationsByUri}
                      {...cardProps}
                    />
                  </Container>
                ) : (
                  <PrintersTable
                    printers={printers}
                    columns={columns}
                    fetching={fetching}
                    pagination={pagination}
                    renderHeaderView={renderHeaderView}
                  />
                )}
              </>
            )}

          </Col>
        </Row>
      </Container>
    </>
  );
});

Printers.propTypes = {
  isGroupQualificationsFeatureEnabled: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  locations: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  subLocationsByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  modelers: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  printers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  printerTypes: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  isGeneralMFGLanguageEnabled: PropTypes.bool.isRequired,
  viewMode: PropTypes.oneOf(Object.values(VIEW_MODE_OPTIONS)).isRequired,
  setViewMode: PropTypes.func.isRequired,
  isCardsView: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    activePage: PropTypes.number,
    pageLimit: PropTypes.number,
    setPage: PropTypes.func,
    totalPaginatedPages: PropTypes.number,
    offset: PropTypes.number,
    nextPage: PropTypes.func,
    prevPage: PropTypes.func,
    totalItems: PropTypes.number,
  }).isRequired,
  filters: PropTypes.shape({
    globalFilter: PropTypes.string,
    setGlobalFilter: PropTypes.func,
  }).isRequired,
  filterValues: PropTypes.shape({
    filterValue: PropTypes.string,
    setFilterValue: PropTypes.func,
  }).isRequired,
};

export default Printers;
