import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _difference from 'lodash/difference';

import * as Selectors from 'rapidfab/selectors';
import { FEATURES, WORKFLOW_USAGE_STATES } from 'rapidfab/constants';

import { Form } from 'react-bootstrap';
import { ProductionWorkflows } from 'rapidfab/components/organize';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';

const { ARCHIVED, ...NON_ARCHIVED_WORKFLOW_USAGE_STATES } = WORKFLOW_USAGE_STATES;
const nonArchivedWorkflowUsageStatesList = Object.values(NON_ARCHIVED_WORKFLOW_USAGE_STATES);

const ProductionWorkflowsContainer = props => {
  const specimenWorkflowFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.SPECIMEN_LIBRARY));
  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);
  const isGeneralMFGLanguageEnabled = useSelector(
    state => Selectors.isFeatureEnabled(state, FEATURES.GENERAL_MFG_LANGUAGE));

  const onCheckboxChange = event => {
    const { checked } = event.target;
    const { onFilterUpdate, filters } = props;
    const { usage_state, ...changedFilters } = filters;

    if (checked) {
      changedFilters.usage_state = nonArchivedWorkflowUsageStatesList;
    }
    onFilterUpdate(changedFilters);
  };

  const { usage_state: usageState } = props.filters;

  return (
    <ProductionWorkflows
      {...props}
      isDebugModeEnabled={isDebugModeEnabled}
      specimenWorkflowFeatureEnabled={specimenWorkflowFeatureEnabled}
      isGeneralMFGLanguageEnabled={isGeneralMFGLanguageEnabled}
      extraFilters={[
        <>
          <Form.Check
            name="hideArchived"
            checked={!_difference(nonArchivedWorkflowUsageStatesList, usageState).length}
            onChange={onCheckboxChange}
            type="checkbox"
            label="Hide Archived"
          />
        </>,
      ]}
    />
  );
};

ProductionWorkflowsContainer.propTypes = {
  onFilterUpdate: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withRecordsListHandling(
  ProductionWorkflowsContainer,
  Selectors.getNonCustomWorkflows,
  ['workflow'],
  {
    staticQueryParams: {
      integrated: false,
    },
  },
);
