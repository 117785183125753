import hexRgb from 'hex-rgb';
import _capitalize from 'lodash/capitalize';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import CostTooltip from 'rapidfab/components/CostTooltip';
import CustomMultiSelect from 'rapidfab/components/forms/CustomMultiSelect';
import ModalGroupedSection from 'rapidfab/components/modals/ModalGroupedSection';
import Documents from 'rapidfab/components/records/Documents';
import MaterialTypeStockModal from 'rapidfab/components/records/MaterialTypeStockModal';
import MaterialTypeStockPanel from 'rapidfab/components/records/MaterialTypeStockPanel';
import MaterialTypeTestPanel from 'rapidfab/components/records/MaterialTypeTestPanel';
import ResourceLink from 'rapidfab/components/ResourceLink';
import { resetRcTooltipInnerStyle, selectInputStyles } from 'rapidfab/constants/styles';
import { ModalGroupedSectionProvider } from 'rapidfab/context/ModalGroupedSectionProvider';
import PrinterTypeIcon from 'rapidfab/icons/printer-type-icon';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import RCTooltip from 'rc-tooltip';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  FormGroup,
  FormLabel,
  FormControl,
  InputGroup,
  Form as BSForm, Button, Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SketchPicker } from 'react-color';
import { FormattedMessage } from 'react-intl';
import {
  DOCUMENT_RELATED_TABLE_NAMES,
  FEATURES,
  MANUFACTURING_PROCESSES, MATERIAL_UNITS_BY_FEATURE,
  MATERIAL_UNITS_BY_UNITS_MEASUREMENT_CORE,
  MATERIAL_UNITS_MEASUREMENT_CORE,
  REACT_SELECT_ACTIONS,
} from 'rapidfab/constants';
import { MATERIAL_FAMILY_MAP, MATERIAL_UNITS_MAP } from 'rapidfab/mappings';
import Feature from 'rapidfab/components/Feature';
import { FormattedMessageMappingOption } from 'rapidfab/i18n';
import { FormControlCost, FormControlSelect, identity } from 'rapidfab/components/formTools';
import { finalFormInputTypes } from 'rapidfab/types';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';
import Loading from 'rapidfab/components/Loading';
import { Form, Field, FormSpy } from 'react-final-form';
import {
  faCircleInfo,
  faClose,
  faExternalLink,
  faFloppyDisk, faObjectGroup,
  faPlus, faTrash,
} from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import 'rapidfab/styles/componentStyles/custom-darken-modal.scss';

const MaterialTypeModal = ({
  uuid,
  stocks,
  locationsByUri,
  materialsByUri,
  onFormSubmit,
  onDelete,
  manufacturers,
  fromTemplate,
  externalMaterialDbFeatureEnabled,
  defaultCurrency,
  printerTypes,
  material,
  materialManagementEnabled,
  initialFormValues,
  submitting,
  materialTestOperationLinkings,
  materialTestOperations,
  materialTestState,
  materialTestInstructions,
  materialTestInstructionsByUri,
  materialTestUnitsByUri,
  isPOCUKOrderFieldsFeatureEnabled,
  handleSubmitManufacturer,
  isManufacturerFetching,
  isVisible,
  deleting,
  handleCloseModal,
  fetching,
  sectionsWithMissingFields,
  handleSetSectionVisibilityState,
  groupedSectionsVisibilityState,
  setPrinterTypesSelected,
  printerTypesSelected,
  setShouldValidateDensity,
}) => {
  const [form, setForm] = useState({ values: initialFormValues });
  const [confirmDelete, setConfirmDelete] = React.useState(false);

  const groupedSectionTitles = {
    general: 'General',
    typesCost: 'Types / Cost',
    advanced: 'Advanced Settings',
  };

  const formattedManufacturers = manufacturers
    .filter(Boolean)
    .map(manufacturer => ({
      label: manufacturer.name,
      value: manufacturer.uri,
    }));

  const formattedMaterialTypes = [{ label: 'Base', value: 'base' }, { label: 'Support', value: 'support' }];

  let rgbaValue = '';
  if (form?.values?.color) {
    // convert hex color value to { r, g, b }
    const { red: r, green: g, blue: b } = hexRgb(form?.values?.color);
    // rgbaValue is what's passed into color picker
    rgbaValue = { r, g, b, a: form?.values?.color_opacity };
  }

  // Density is needed for Weight User Units only
  const showDensity =
    MATERIAL_UNITS_BY_UNITS_MEASUREMENT_CORE[MATERIAL_UNITS_MEASUREMENT_CORE.WEIGHT]
      .includes(form?.values?.units);

  useEffect(() => {
    setShouldValidateDensity(showDensity);
  }, [showDensity]);

  const [showModal, setShowModal] = useState(false);
  const showUnits = useMemo(() => {
    if (isPOCUKOrderFieldsFeatureEnabled) {
      return MATERIAL_UNITS_BY_FEATURE[FEATURES.POC_UK_ORDER_FIELDS];
    }
    return Object.keys(MATERIAL_UNITS_MAP);
  }, [isPOCUKOrderFieldsFeatureEnabled]);

  const handleManufacturersChange = async (selectedOption, actionMeta, onChange) => {
    // The user would like to create the new manufacturer "on the fly"
    if (actionMeta.action === REACT_SELECT_ACTIONS.CREATE_OPTION) {
      // Send API request to create the new manufacturer with the "name" field
      const manufacturerUri = await handleSubmitManufacturer(selectedOption.label);
      if (manufacturerUri) {
        // Set the new manufacturer URI as the value of the select input
        return onChange(manufacturerUri);
      }
    }
    const newValue = selectedOption ? selectedOption.value : '';
    return onChange(newValue);
  };

  const materialTypeNameTooltipLength = 50;

  const handleConfirmDeletePrinterType = () => {
    setConfirmDelete(false);
    return onDelete(initialFormValues?.uuid);
  };

  return (
    <Modal
      size="lg"
      show={isVisible || uuid || initialFormValues?.uuid}
      onHide={handleCloseModal}
      scrollable
      backdrop="static"
      dialogClassName="custom-darken-modal"
    >
      {
        fetching ? <Loading className="p-a-md" /> : (
          <Form
            onSubmit={onFormSubmit}
            initialValues={initialFormValues}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Header>
                  <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faObjectGroup} />

                      <p className="custom-darken-modal-title-text">
                        {initialFormValues?.uuid && initialFormValues?.name ?
                          (
                            initialFormValues.name.length >= materialTypeNameTooltipLength ?
                              (
                                <div className="d-flex align-items-center">
                                  <RCTooltip
                                    placement="bottom"
                                    id="materialTypeNameTooltip"
                                    destroyTooltipOnHide
                                    overlayInnerStyle={resetRcTooltipInnerStyle}
                                    mouseLeaveDelay={0.4}
                                    overlay={(
                                      <p className="mb0 darkTooltip">
                                        {_capitalize(initialFormValues.name)}
                                      </p>
                                    )}
                                  >
                                    <div className="d-flex align-items-center">
                                      <p className="mb0 truncate capitalize custom-darken-modal-title-text-title">
                                        {_capitalize(initialFormValues.name)}
                                      </p>
                                      ({getShortUUID(initialFormValues.uuid)})
                                    </div>
                                  </RCTooltip>
                                </div>
                              ) : (
                                <span>{_capitalize(initialFormValues.name)}
                                    &nbsp;({getShortUUID(initialFormValues.uuid)})
                                </span>
                              )
                          ) : 'Create Material'}
                      </p>
                    </div>
                    <FontAwesomeIcon
                      icon={faClose}
                      onClick={handleCloseModal}
                      tabIndex={0}
                      role="button"
                      className="custom-darken-modal-button"
                    />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="custom-darken-modal--body-scroll">
                  <Row className="custom-darken-modal-custom-gutter">

                    <ModalGroupedSectionProvider
                      sectionsWithMissingFields={sectionsWithMissingFields}
                      groupedSectionTitles={groupedSectionTitles}
                      handleSetSectionVisibilityState={handleSetSectionVisibilityState}
                    >
                      <ModalGroupedSection
                        isSectionVisible={groupedSectionsVisibilityState.general}
                        sectionTitle={groupedSectionTitles.general}
                      >
                        <Row>
                          <Col lg={6} xs={12}>
                            <FormGroup className="form-group" controlId="uxName">
                              <FormLabel>
                                <FormattedMessage id="field.name" defaultMessage="Name" />: *
                              </FormLabel>
                              <Field
                                name="name"
                                type="text"
                                render={props => (
                                  <FormControl
                                    {...props.input}
                                    required
                                    disabled={fromTemplate}
                                  />
                                )}
                              />
                            </FormGroup>

                            <FormGroup className="form-group" controlId="uxDescription">
                              <FormLabel>
                                <FormattedMessage
                                  id="field.description"
                                  defaultMessage="Description"
                                />: *
                              </FormLabel>
                              <Field
                                name="description"
                                render={props => (
                                  <FormControl
                                    {...props.input}
                                    as="textarea"
                                    required
                                  />
                                )}
                              />
                            </FormGroup>

                            <FormGroup className="form-group" controlId="uxManufacturer">
                              <FormLabel className="d-flex align-items-center">
                                <FormattedMessage
                                  id="field.manufacturer"
                                  defaultMessage="Manufacturer"
                                />
                                : *
                                {values?.manufacturer && (
                                  <ResourceLink
                                    uri={values.manufacturer}
                                    name={(
                                      <FontAwesomeIcon
                                        icon={faExternalLink}
                                        className="spacer-left"
                                      />
                                    )}
                                  />
                                )}
                              </FormLabel>
                              <InputGroup>
                                <Field
                                  name="manufacturer"
                                  render={props => {
                                    const selectedOption = formattedManufacturers.find(option =>
                                      option.value === props.input.value) || null;
                                    return (
                                      <CreatableSelect
                                        {...props.input}
                                        styles={selectInputStyles}
                                        isLoading={isManufacturerFetching}
                                        placeholder={(
                                          <FormattedMessage
                                            id="field.selectManufacturer"
                                            defaultMessage="Select Manufacturer"
                                          />
                                        )}
                                        isDisabled={isManufacturerFetching}
                                        components={{
                                          LoadingIndicator: () => (<Loading inline className="spacer-right" />),
                                        }}
                                        value={selectedOption}
                                        options={formattedManufacturers}
                                        required
                                        onChange={(value, actionMeta) =>
                                          handleManufacturersChange(value, actionMeta, props.input.onChange)}
                                        isClearable
                                      />
                                    );
                                  }}
                                />
                              </InputGroup>
                            </FormGroup>

                            <FormGroup className="form-group" controlId="uxReferenceColor">
                              <FormLabel>
                                <FormattedMessage id="field.reference_color" defaultMessage="Reference Color" />
                              </FormLabel>
                              <Field
                                name="color"
                                render={color_props => (
                                  <Field
                                    name="color_opacity"
                                    render={props => (
                                      <SketchPicker
                                        width={250}
                                        presetColors={[]}
                                        color={rgbaValue}
                                        onChangeComplete={({ rgb: { a: opacity }, hex }) => {
                                          // color field stored as hex in color field
                                          color_props.input.onChange(hex === 'transparent' ? '#000000' : hex);
                                          // opacity float is stored in its own color_opacity field
                                          props.input.onChange(opacity);
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              />
                            </FormGroup>

                          </Col>
                          <Col lg={6} xs={12}>

                            {uuid && (
                              <MaterialTypeStockPanel
                                stocks={stocks}
                                isCustomDarkCardStyle
                                locationsByUri={locationsByUri}
                                materialsByUri={materialsByUri}
                                onOpenModal={() => setShowModal(true)}
                                materialManagementEnabled={materialManagementEnabled}
                              />
                            )}

                            <Feature
                              featureName={FEATURES.MATERIAL_TEST_PANEL}
                            >
                              <MaterialTypeTestPanel
                                {...materialTestState}
                                materialTestInstructions={materialTestInstructions}
                                materialTestInstructionsByUri={materialTestInstructionsByUri}
                                materialTestOperations={materialTestOperations}
                                materialTestOperationLinkings={materialTestOperationLinkings}
                                materialTestUnitsByUri={materialTestUnitsByUri}
                                material={material}
                                isCustomDarkCardStyle
                              />
                            </Feature>

                            {uuid && (
                              <Documents
                                isCustomDarkCardStyle
                                panelTitle="Documents"
                                relatedTable={DOCUMENT_RELATED_TABLE_NAMES.MATERIAL}
                                relatedUUID={uuid}
                              />
                            )}

                          </Col>
                        </Row>
                      </ModalGroupedSection>

                      <ModalGroupedSection
                        isSectionVisible={groupedSectionsVisibilityState.typesCost}
                        sectionTitle={groupedSectionTitles.typesCost}
                      >
                        <Row>
                          <Col lg={6} xs={12}>
                            <FormGroup className="form-group" controlId="uxType">
                              <FormLabel>
                                <FormattedMessage
                                  id="field.materialType"
                                  defaultMessage="Material Type"
                                />
                                : *
                              </FormLabel>
                              <Field
                                name="type"
                                render={({ input }) => {
                                  const selectedOption = formattedMaterialTypes.find(option =>
                                    option.value === input.value) || null;
                                  return (
                                    <Select
                                      options={formattedMaterialTypes}
                                      onChange={option =>
                                        input.onChange(option?.value || null)}
                                      placeholder="Select a Material Type"
                                      value={selectedOption}
                                      styles={selectInputStyles}
                                      isClearable
                                      required
                                    />
                                  );
                                }}
                              />
                            </FormGroup>

                            <FormGroup className="form-group" controlId="uxPrinterTypes">
                              <Field
                                name="printer_types_selected"
                                initialValue={printerTypesSelected}
                                render={({ input }) => (
                                  <CustomMultiSelect
                                    value={input.value}
                                    customIcon={(<PrinterTypeIcon className="custom-navbar-icon-white" />)}
                                    onChange={selectedOptions => {
                                      setPrinterTypesSelected(selectedOptions);
                                      input.onChange(selectedOptions);
                                    }}
                                    defaultNullableValue={null}
                                    formLabel={(
                                      <FormLabel>
                                        <UseNonMfgLanguageFeature
                                          mfgLanguageComponent={(
                                            <FormattedMessage
                                              id="manage.printerTypes"
                                              defaultMessage="Printer Types"
                                            />
                                          )}
                                          nonMfgLanguageComponent={(
                                            <FormattedMessage
                                              id="mfg.printerTypes.productionDeviceTypes"
                                              defaultMessage="Production Device Types"
                                            />
                                          )}
                                        />
                                        :
                                      </FormLabel>
                                    )}
                                    options={printerTypes}
                                    placeholder="Select Printer Types"
                                  />
                                )}
                              />
                            </FormGroup>
                          </Col>

                          <Col lg={6} xs={12}>

                            <Row>
                              <Col sm={6}>
                                <FormGroup className="form-group" controlId="uxCost">
                                  <FormLabel>
                                    <FormattedMessage
                                      id="field.costPerUnit"
                                      defaultMessage="Cost Per Unit"
                                    />
                                    : *
                                    <CostTooltip />
                                  </FormLabel>
                                  <FormControlCost
                                    name="cost"
                                    initialValue={initialFormValues?.cost}
                                    type="number"
                                    required
                                    currency={defaultCurrency}
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm={6}>
                                <FormGroup className="form-group" controlId="uxUnits">
                                  <FormLabel>
                                    <FormattedMessage id="field.units" defaultMessage="Units" />: *
                                  </FormLabel>
                                  <Field
                                    name="units"
                                    render={props => (
                                      <FormControlSelect
                                        {...props.input}
                                        required
                                      >
                                        {!props.input.value && (
                                          <FormattedMessage id="field.none" defaultMessage="None">
                                            {text => (
                                              <option value="" selected disabled>
                                                {text}
                                              </option>
                                            )}
                                          </FormattedMessage>
                                        )}
                                        {showUnits.map(unit => (
                                          <FormattedMessageMappingOption
                                            mapping={MATERIAL_UNITS_MAP}
                                            value={unit}
                                          />
                                        ))}
                                      </FormControlSelect>
                                    )}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <FormGroup className="form-group" controlId="uxFamily">
                              <FormLabel>
                                <FormattedMessage
                                  id="field.materialFamily"
                                  defaultMessage="Material Family"
                                />
                                :
                              </FormLabel>
                              <Field
                                name="material_family"
                                render={props => (
                                  <FormControlSelect
                                    {...props.input}
                                    disabled={fromTemplate}
                                  >
                                    <FormattedMessage id="field.none" defaultMessage="None">
                                      {text => <option value="">{text}</option>}
                                    </FormattedMessage>
                                    {Object.keys(MATERIAL_FAMILY_MAP).map(family => (
                                      <FormattedMessageMappingOption
                                        mapping={MATERIAL_FAMILY_MAP}
                                        value={family}
                                      />
                                    ))}
                                  </FormControlSelect>
                                )}
                              />
                            </FormGroup>
                            {showDensity && (
                              <FormGroup controlId="uxDensity">
                                <FormLabel>
                                  {
                                    // Density is fixed to `g/cm3`
                                    // and not related to selected units (g/kg/lb) so hard-coding it here
                                  }
                                  <FormattedMessage
                                    id="field.density"
                                    defaultMessage="Density"
                                  />{' '}
                                  (g/cm3): *
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={(
                                      <Tooltip>
                                        <FormattedMessage
                                          id="density.Tooltip"
                                          defaultMessage="Fused density required for weight based material measurements. See your material data sheet."
                                        />
                                      </Tooltip>
                                    )}
                                  >
                                    <FontAwesomeIcon icon={faCircleInfo} className="spacer-left" />
                                  </OverlayTrigger>
                                </FormLabel>
                                <Field
                                  name="density"
                                  type="number"
                                  render={props => (
                                    <FormControl
                                      {...props.input}
                                      required
                                    />
                                  )}
                                />
                              </FormGroup>
                            )}

                          </Col>
                        </Row>
                      </ModalGroupedSection>

                      <ModalGroupedSection
                        isSectionVisible={groupedSectionsVisibilityState.advanced}
                        sectionTitle={groupedSectionTitles.advanced}
                      >
                        <Row>
                          <Col lg={6} xs={12}>
                            <FormGroup className="form-group" controlId="uxExternalDatasheet">
                              <FormLabel>
                                <FormattedMessage
                                  id="field.externalDatasheetUrl"
                                  defaultMessage="External Datasheet URL"
                                />
                                :
                              </FormLabel>
                              <Field
                                name="external_datasheet_url"
                                type="url"
                                parse={identity}
                                render={props => (
                                  <FormControl {...props.input} />
                                )}
                              />
                            </FormGroup>
                            <FormGroup className="form-group" controlId="uxExternalID">
                              <FormLabel>
                                <FormattedMessage id="field.erpId" defaultMessage="ERP ID" />:
                              </FormLabel>
                              <Field
                                name="external_id"
                                parse={identity}
                                type="text"
                                render={props => (
                                  <FormControl {...props.input} />
                                )}
                              />
                            </FormGroup>

                          </Col>
                          <Col lg={6} xs={12}>
                            {externalMaterialDbFeatureEnabled ? (
                              <FormGroup
                                className="form-group"
                                controlId="manufacturingProcess"
                              >
                                <FormLabel>
                                  <FormattedMessage
                                    id="field.manufacturing_process"
                                    defaultMessage="Manufacturing Process"
                                  />
                                  :
                                </FormLabel>
                                <Field
                                  name="manufacturing_process"
                                  render={props => (
                                    <FormControlSelect
                                      {...props.input}
                                    >
                                      <option value="">None</option>
                                      {MANUFACTURING_PROCESSES.map(process => (
                                        <option value={process} key={process}>
                                          {process}
                                        </option>
                                      ))}
                                    </FormControlSelect>
                                  )}
                                />
                              </FormGroup>
                            ) : (
                              <Field
                                name="third_party_fulfillment"
                                type="checkbox"
                                initialValue={initialFormValues?.third_party_fulfillment}
                                render={props => (
                                  <BSForm.Check
                                    {...props.input}
                                    label={(
                                      <FormattedMessage
                                        id="field.fullfilledByThirdParty"
                                        defaultMessage="Fullfilled By Third Party"
                                      />
                                    )}

                                  />
                                )}
                              />
                            )}
                          </Col>
                        </Row>
                      </ModalGroupedSection>

                    </ModalGroupedSectionProvider>

                  </Row>
                </Modal.Body>
                <Modal.Footer>

                  {
                    initialFormValues?.id && (
                      <Button
                        disabled={submitting || deleting}
                        variant="danger"
                        onClick={() => setConfirmDelete(true)}
                      >
                        {deleting ? <Loading /> : (
                          <>
                            <FontAwesomeIcon icon={faTrash} className="spacer-right" />
                            <FormattedMessage id="button.delete" defaultMessage="Delete" />
                          </>
                        )}
                      </Button>
                    )
                  }

                  <Button type="submit" disabled={submitting} variant="success">
                    {submitting ? <Loading /> : (
                      <>
                        <FontAwesomeIcon
                          icon={initialFormValues?.id ? faFloppyDisk : faPlus}
                          className="spacer-right"
                        />
                        <FormattedMessage
                          id={initialFormValues?.id ? 'button.save' : 'button.createResource'}
                          defaultMessage={initialFormValues?.id ? 'Save' : 'Create'}
                        />
                      </>
                    )}
                  </Button>
                </Modal.Footer>
                <FormSpy
                  subscription={{ values: true }}
                  onChange={values => setForm(values)}
                />
              </form>
            )}
          />
        )
      }
      {showModal && (
        <MaterialTypeStockModal
          material={material}
          onClose={() => setShowModal(false)}
        />
      )}
      {confirmDelete && (
        <ConfirmationModal
          handleCancel={() => setConfirmDelete(false)}
          handleConfirm={handleConfirmDeletePrinterType}
          message={(
            <FormattedMessage
              id="message.entityDeletePrompt"
              defaultMessage="Are you sure you want to delete this {entityType}?"
              values={{ entityType: 'Material' }}
            />
          )}
          confirmButtonContent={<FormattedMessage id="button.delete" defaultMessage="Delete" />}
        />
      )}
    </Modal>
  );
};

MaterialTypeModal.propTypes = {
  stocks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  locationsByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  uuid: PropTypes.string.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  input: finalFormInputTypes.isRequired,
  manufacturers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fromTemplate: PropTypes.bool,
  externalMaterialDbFeatureEnabled: PropTypes.bool,
  defaultCurrency: PropTypes.string.isRequired,
  printerTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  material: PropTypes.shape({}).isRequired,
  materialManagementEnabled: PropTypes.bool.isRequired,
  initialFormValues: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    manufacturer: PropTypes.string,
    material_type: PropTypes.string,
    density: PropTypes.number,
    printer_types_selected: PropTypes.arrayOf(PropTypes.string),
    cost: PropTypes.number,
    manufacturing_process: PropTypes.string,
    external_datasheet_url: PropTypes.string,
    external_id: PropTypes.string,
    third_party_fulfillment: PropTypes.bool,
    units: PropTypes.string,
    printer_type_selector: PropTypes.string,
    material_family: PropTypes.string,
    type: PropTypes.string,
    color: PropTypes.string,
    id: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
  materialsByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  submitting: PropTypes.bool.isRequired,
  materialTestOperationLinkings: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  materialTestState: PropTypes.arrayOf(PropTypes.func).isRequired,
  materialTestOperations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  materialTestInstructions: PropTypes.arrayOf(PropTypes.func).isRequired,
  materialTestInstructionsByUri: PropTypes.shape({}).isRequired,
  materialTestUnitsByUri: PropTypes.shape({}).isRequired,
  isPOCUKOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  handleSubmitManufacturer: PropTypes.func.isRequired,
  isManufacturerFetching: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  deleting: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  sectionsWithMissingFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSetSectionVisibilityState: PropTypes.func.isRequired,
  groupedSectionsVisibilityState: PropTypes.shape({
    general: PropTypes.bool,
    typesCost: PropTypes.bool,
    advanced: PropTypes.bool,
  }).isRequired,
  setPrinterTypesSelected: PropTypes.func.isRequired,
  printerTypesSelected: PropTypes.arrayOf(PropTypes.string).isRequired,
  setShouldValidateDensity: PropTypes.func.isRequired,
};

MaterialTypeModal.defaultProps = {
  fromTemplate: false,
  externalMaterialDbFeatureEnabled: false,
};

export default MaterialTypeModal;
